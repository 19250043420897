
@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  overflow-x: hidden;
}

/* ====== Custom scroll bar */

.container-invester-guide .row1-parent-invter-guide .slider-invter-guide-sec .Swiper-class-custem {
  /* z-index: 99; */
}
::-webkit-scrollbar {
    width: 5px;
    background: #efefef;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 1em ;
    background: linear-gradient(-45deg,  #fbb72f,#0382c8, #fbb72f,#0382c8);
    
  }