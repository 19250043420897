/* line :>240 main.js styling */
/* line :  investing compnies styling */
* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
/* ::-webkit-scrollbar{
  display: none;
} */
.nav {
  position: fixed;
  width: 100%;
  z-index: 99;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.21);
  backdrop-filter: blur(5.7px);
  -webkit-backdrop-filter: blur(5.7px);
}
.nav.scroll {
  background-color: #fff;
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
  transform: translateY(-10px);
  transition: 0.5s ease-in-out;
}
.navbar {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  padding: 10px 0px !important;
}
.logo {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 8% !important;
}

.logo img {
  width: 100%;
}

.nav ul {
  /* border: 1px solid green; */
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 65%;
}
.nav ul > li {
  display: inline-block;
  margin: 0 9px;
  color: #ffffff;
  transition: 0.3s ease;
}
.nav ul > li:hover {
  box-shadow: inset 0 -2px 0 #fbb72f;
  color: #fbb72f !important;
  /* padding: 20px 3px; */
  padding: 20px 0px;
}
.nav ul li > a {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  /* font-size: 19px; */
  font-size: 1.3vw;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #333333;
}
.nav ul li > a:hover {
  color: #fbb72f;
}
.nav ul li a.active {
  color: #fbb72f;
  font-weight: 600;
}

.checkbtn {
  font-size: 30px;
  color: #0382c8;
  float: right;
  line-height: 80px;
  margin-right: 40px;
  cursor: pointer;
  display: none;
  width: 100%;
}
.checkbtn i.scroll {
  color: #0382c8;
}
#check {
  display: none;
}
.small_screen {
  /* border: 2px solid black; */
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* ********** Dropdown *******************  */
.dropdown {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  /* font-size: 19px; */
  font-size: 1.4vw;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #333333;
}
/* Dropdown content (hidden by default) */

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #dedede;
  border: 1px solid #828282;
  min-width: 205px;
  z-index: 999;
  margin-top: 8px;
}
.small_screen::-webkit-scrollbar {
  display: none; /* Safari and Chrome browsers */
}
.tooltip {
  position: relative; /* making the .tooltip span a container for the tooltip text */
}
.tooltip:before {
  content: attr(dropdown-content); /* here's the magic */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  margin-left: -80px;
  width: 80px;
  height: 50px;
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  background: none;
  color: #fff;
  text-align: center;
  display: block;
}
.tooltip:hover:before {
  display: block;
}
.tooltip.left:before {
  left: initial;
  margin: initial;
  right: 100%;
  margin-right: 15px;
}
.tooltip:after {
  content: "";
  position: absolute;
  left: 35%;
  margin-left: -10px;
  top: 50%;
  z-index: 999;
  transform: rotate(90deg);
  margin-top: -13px;
  border: 11px solid #333333;
  border-color: transparent #333333 transparent transparent;
  display: none;
}
.tooltip:hover:before,
.tooltip:hover:after {
  display: block;
}
/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.01em;
  color: #333333;
  text-decoration: none;
  display: block;
  text-align: left;
  padding: 10px 16px;
}
.dropdown-content a:hover {
  background-color: #fff;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
  border: 1px solid rgb(48, 45, 45);
}

.tender_btn {
  padding: 5px 10px;
  background-color: #f9b734;
  border-radius: 5px;
  display: inline-block;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  animation: example 1s infinite;
}

@keyframes example {
  0% {
    background-color: #0294d5;
  }
  50% {
    background-color: #f9b734;
  }
  100% {
    background-color: #8ed211;
  }
}
.App_popup {
  width: 90%;
  margin: 0px auto;
  height: auto;
  padding-top: 100px;
}
.tender_instruction {
  padding-bottom: 20px;
}
.tender_instruction > img {
  width: 75%;
  margin: 0 auto;
}
.tender_instruction > h2 {
  margin: 10px 0px;
  color: #000000;
  font-weight: 600;
}
.tender_instruction > li {
  margin: 10px 0px;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.social_icon {
  width: 18%;
  padding: 10px 0px;
}
.social_icon .social_icons_container a {
  width: 26px;
  height: 26px;
  margin: 0px 4px;
  font-size: 30px;
  /* width: 100%; */
}

.social_icons_container {
  width: 100%;
  height: 7vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 16px;
}
.social_icons_container > a {
  margin-right: 13px;
}
.social_icon .facebookIcon {
  font-size: 30px !important;
  color: #4267b2;
}
.social_icon .facebookIcon:hover,
.social_icon .linkinIcon:hover,
.social_icon .TwitterIcon:hover {
  color: #222222;
  opacity: 0.5;
}
.social_icon .linkinIcon {
  font-size: 40px !important;
  color: #0077b5;
  margin-bottom: 10px;
  /* border: 0.5px solid red !important; */
}
.social_icon .linkinIcon2 {
  font-size: 35px !important;
  color: rgba(134, 57, 200, 1);
  /* margin-bottom: 10px; */
  /* border: 0.5px solid red !important; */
}
.social_icon .TwitterIcon {
  font-size: 33px !important;
  color: #55acee;
  /* border: 0.5px solid red !important; */
}

@media (max-width: 952px) {
  .navbar {
    width: 97%;
    align-items: center;
  }
  .logo {
    width: 10%;
  }
  .nav ul {
    width: 70%;
  }

  .social_icon {
    width: 32%;
    padding: 0px 0px;
    margin-right: 5px;
    justify-content: center;
    padding: 0px 0px;
  }
  .social_icon i {
    font-size: 25px;
    margin-left: 10px;
  }
  .regitr_btn {
    width: 20%;
  }
  .contact_btn > a {
    font-size: 15px !important;
    padding: 3px 5px !important;
  }
  .social_icons_container {
    width: 30%;
    height: 7vh;
    padding-left: 5px;
  }
  .social_icons_container > a {
    margin-right: 5px;
  }
}
@media (max-width: 858px) {
  .checkbtn {
    display: flex;
    justify-content: end;
    width: 10%;
    margin-top: 20px;
    font-size: 20px;
  }
  .navbar .small_screen {
    position: fixed;
    list-style: none;
    width: 100%;
    height: 100vh;
    background: #2c3e50;
    top: 110px;
    left: -100%;
    text-align: center;
    transition: all 0.5s;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    z-index: 10;
  }
  .navbar ul {
    margin: 20px 0px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .nav .navbar ul li {
    display: block;
    line-height: 40px;
  }
  .nav ul > li:hover {
    box-shadow: 0px;
    color: #fbb72f !important;
    /* padding: 20px 3px; */
    padding: 0px 0px;
  }
  .nav ul li a {
    font-size: 20px;
    color: #fff;
  }
  .dropdown {
    font-size: 20px;
    color: #fff;
  }
  a:hover {
    background: none;
    color: #87522e;
  }
  #check:checked ~ .small_screen {
    left: 0;
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15% !important;
  }
  .regitr_btn {
    flex-direction: column;
  }
  .regitr_btn a {
    margin: 10px 0px;
  }
  .logo img {
    width: 100%;
  }
  .dropdown {
    color: #fff;
  }
  .contact_btn a:hover:after {
    opacity: 0;
  }
}
@media (max-width: 768px) {
  .social_icon {
    flex-direction: column;
  }
  .contact_btn {
    margin-right: 0px;
  }
  .social_icon a {
    font-size: 17px;
  }
  .social_icons_container {
    width: 50%;
    height: 11vh;
    padding-left: 3px;
    border-left: none;
  }
  .logo {
    width: 15% !important;
    margin-top: 12px;
  }
  .tooltip:before {
    margin-left: -86px;
    width: 80px;
    height: 5px;
    margin-top: -10px;
  }
  .tender_instruction > img {
    width: 95%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 540px) {
  .navbar .small_screen {
    width: 100%;
    top: 85px;
  }
  .logo {
    width: 20% !important;
  }
}
/* main css */
.main_container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.main_container img {
  width: 100%;
  height: -webkit-fill-available;
  height: inherit;
  position: relative;
}
.initailLocation {
  position: absolute;
  width: 60px;
  height: 60px;
  /* left: 1250px; */
  right: 50px;
  top: 530px;
}

.main_container .container_parent {
  width: 90%;
  height: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  /* border: 1px solid red;    */
  /* z-index: 999; */
  position: absolute;
}
.row1 {
  width: 45%;
  margin-top: 150px;
  /* border: 2px solid green; */
}
.row1 h3 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-bottom: 8px;
  background: linear-gradient(
    271.03deg,
    #f89152 11.77%,
    #0993d3 25.61%,
    #f8a913 37.03%,
    #068ace 88.81%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.row1 h2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 58px;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-top: 8px;
}
.row2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.row2 .col-img1 {
  width: 40%;
  margin-top: 4px;
  display: none;
}
.main_container .container_parent .row2 .col-img1 img {
  width: 100%;
}

.main_container .container_parent .row2 .col-container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.main_container .container_parent .row2 .col1 {
  margin-right: 38px;
}
.main_container .container_parent .row2 .col1 p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #ffffff;
}
.icon-flag {
  position: absolute;
  width: 50px;
  height: 60px;
  right: 4%;
  top: 80%;
}
/* ((((((((((investing Compnies styling section start here )))))))))) */
.container_compnies {
  width: 100%;
  background-color: #e0e0e0;
}
.container_compnies h2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.01em;
  color: #222222;
  padding-top: 23px;
  text-align: center;
}
.container_compnies .row_compneis {
  max-width: 90%;
  margin: 0 auto;
  padding-bottom: 23px;
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.container_compnies .row_compneis .col1_compnes {
  width: 12%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;
}
.container_compnies .row_compneis .col1_compnes:hover,
.container_compnies .row_compneis .col1_compnes:focus {
  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  transform: translateX(-5px);
}
.container_compnies .row_compneis .col1_compnes img {
  width: 42.52px;
  /* height: 44px; */
  margin-top: 16px;
  margin-bottom: 8px;
}
.container_compnies .row_compneis .col1_compnes p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #333333;
  margin-bottom: 24px;
}

/* investing compamies styling start here  */

.main-container_3 {
  width: 100%;
  height: auto;
  background: #d9d9d9;
}
.image_impact_investing {
  width: 100%;
  background-image: url("../Images/Rectangle_min.png");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.image_impact_investing .impact_center_flex {
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 60px 0px;
}
.image_impact_investing .impact_center_flex > h2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  /* font-size: 40px; */
  font-size: 4vw;
  line-height: 50px;
  letter-spacing: 0.01em;
  color: #ffffff;
  text-align: center;
}
.cards_impact_container {
  margin: 30px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Set the content width to 100% of the container */
  height: 100%; /* Set the content height to 100% of the container */
  padding: 10px; /* Add padding to the content */
  box-sizing: border-box;
}
.cards_impact_container .impact_cards {
  width: 30%;
  height: 250px;
  border: 1px solid #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.5s;
}
.cards_impact_container .impact_cards:hover {
  transform: scale(1.1);
}
.cards_impact_container .impact_cards > img {
  width: 55px;
  margin: 10px 0px;
}
.cards_impact_container .impact_cards > h2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  text-align: center;
}
.cards_impact_container .impact_cards > p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin: 10px 0px;
}
.cards_impact_container .impact_cards > a {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #0993d3;
  transition: all 0.3s;
}
.cards_impact_container .impact_cards > a:hover {
  border-bottom: 1px solid #0993d3;
}

.impact_paragraph {
  width: 90%;

  margin: 0 auto;
  padding: 30px 0px;
  text-align: center;
}
.impact_paragraph > p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: #333333;
  margin-bottom: 25px;
}
.impact_paragraph > a {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #027cc5;
  padding: 12px 24px;
  margin: 20px 0px;
  text-align: center;
  background: rgba(255, 255, 255, 0.02);
  /* Primaryy */

  border: 1.5px solid #027cc5;
  /* Button */

  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
}

.main-container-3 .row-section-3-parent {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-container-3 .row-section-3-parent .col-1-image1-sec {
  width: 35%;
}
.main-container-3 .row-section-3-parent .col-1-image1-sec img {
  width: 100%;
  transition: all 0.3s;
}
.main-container-3 .row-section-3-parent .col-1-image1-sec img:hover {
  transform: scale(0.8);
}
.main-container-3 .row-section-3-parent .col-2-contant-sec {
  width: 60%;
  margin: 50px 0px;
}

.main-container-3 .row-section-3-parent .col-2-contant-sec h2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.01em;
  color: #222222;
  margin-bottom: 12px;
}
.main-container-3 .row-section-3-parent .col-2-contant-sec p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: #333333;
  margin: 12px 0px;
}
.main-container-3 .row-section-3-parent .col-2-contant-sec .box-3-section {
  width: 100%;
  height: 242px;
  margin: 12px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.main-container-3
  .row-section-3-parent
  .col-2-contant-sec
  .box-3-section
  .boxs-same-css {
  width: 31%;
  height: 242px;
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid #333333;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
  /* transition: transform 0.8s; */
  transition: all 0.5s;
}
.main-container-3
  .row-section-3-parent
  .col-2-contant-sec
  .box-3-section
  .boxs-same-css:hover {
  transform: scale(1.1);
}

.main-container-3
  .row-section-3-parent
  .col-2-contant-sec
  .box-3-section
  .boxs-same-css
  span {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.01em;
  color: #333333;
}
.main-container-3
  .row-section-3-parent
  .col-2-contant-sec
  .box-3-section
  .boxs-same-css
  p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #000000;
  text-align: start;
  margin-top: 8px;
}
.main-container-3
  .row-section-3-parent
  .col-2-contant-sec
  .box-3-section
  .boxs-same-css
  p
  a {
  color: #0077b5;
  cursor: pointer;
}
/* ***************** (slider_Invster) ******************  */

.container-invester-guide {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.container-invester-guide img {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.container-invester-guide 
.row1-parent-invter-guide {
  width: 90%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
}
.container-invester-guide .row1-parent-invter-guide .txt-invster-g {
  width: 60%;
  margin: 20px auto 5px auto;
  text-align: center;
}
.container-invester-guide .row1-parent-invter-guide .txt-invster-g h2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #333333;
  /* margin-top: 25px; */
}
.container-invester-guide .row1-parent-invter-guide .txt-invster-g p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #333333;
  margin-top: 16px;
}
.container-invester-guide
  .row1-parent-invter-guide
  .txt-invster-g
  .invester-para-12 {
  margin-bottom: 32px;
}
.container-invester-guide .row1-parent-invter-guide .txt-invster-g .slide-btn2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #027cc5;
  padding: 12px 24px;
  background: rgba(255, 255, 255, 0.02);
  border: 1.5px solid #027cc5;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.container-invester-guide
  .row1-parent-invter-guide
  .txt-invster-g
  .slide-btn2::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.02);
  z-index: -2;
}
.container-invester-guide
  .row1-parent-invter-guide
  .txt-invster-g
  .slide-btn2::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #f47621;
  opacity: 0.4;
  transition: all 0.3s;
  z-index: -1;
}
.container-invester-guide
  .row1-parent-invter-guide
  .txt-invster-g
  .slide-btn2:hover {
  color: #fff;
}
.container-invester-guide
  .row1-parent-invter-guide
  .txt-invster-g
  .slide-btn2:hover::before {
  width: 100%;
}
.container-invester-guide .row1-parent-invter-guide .slider-invter-guide-sec {
  width: 100%;
  margin: 25px auto 0px auto;
  text-align: center;

}
.container-invester-guide
  .row1-parent-invter-guide
  .slider-invter-guide-sec
  .Swiper-class-custem {
  width: 100%;
  height: 30vh;
  padding: 10px 20px;
}
.container-invester-guide
  .row1-parent-invter-guide
  .slider-invter-guide-sec
  .slider-items {
  width: 20%;
  height: 160px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #ffffff;
  border: 0.5px solid #f89152;
  padding: 50px 26px 40px 26px;
}

.container-invester-guide
  .row1-parent-invter-guide
  .slider-invter-guide-sec
  .slider-items
  img {
  /* width: 20%; */
  width: 34px;
  height: 34px;
}
.container-invester-guide
  .row1-parent-invter-guide
  .slider-invter-guide-sec
  .slider-items
  p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #333333;
  margin-top: 8px;

  text-overflow: ellipsis;
}
.next_prev_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.prev-custom-css {
  width: 43px !important;
  height: 43px !important;
}
.prev-custom-css img,
.next-custom-css img {
  width: 43px !important;
  height: 43px !important;
}
.prev-custom-css img:hover,
.next-custom-css img:hover {
  cursor: pointer;
}

/* section-5 styling start here  */
.container-5-section {
  width: 100%;
  height: auto;
  background-color: #e0e0e0;
}
.container-5-section .row1-section-5-parent {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container-5-section .row1-section-5-parent .col-box1-section-5 {
  width: 20%;
  height: 237px;
  /* border: 1px solid red; */
  margin: 80px 0px;
  padding-top: 24px;
  padding-left: 15px;
  padding-bottom: 30px;
  transition: all 0.3s ease-out;
}
.container-5-section .row1-section-5-parent .col-box1-section-5:hover {
  transform: translateY(-5px);
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}

.container-5-section
  .row1-section-5-parent
  .col-box1-section-5
  .image-section-5 {
  width: 20%;
}
.container-5-section
  .row1-section-5-parent
  .col-box1-section-5
  .image-section-5
  img {
  width: 100%;
  margin-top: 4px;
}
.container-5-section .row1-section-5-parent .col-box1-section-5 .h3-heading {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 75px;
  color: #333333;
  margin-bottom: 8px;
}
.container-5-section
  .row1-section-5-parent
  .col-box1-section-5
  .h3-heading
  .span-heading1 {
  font-size: 20px;
  text-transform: capitalize;
}
.container-5-section .row1-section-5-parent .col-box1-section-5 p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: #333333;
}

/* section-6 styling start here  */
.container-6-sect {
  width: 100%;
  height: auto;
}
.container-6-sect .row-6-section-parent {
  margin-left: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container-6-sect .row-6-section-parent .col-txt-section-6 {
  width: 45%;
}
.container-6-sect .row-6-section-parent .col-image-section-6 {
  width: 55%;
}
.container-6-sect .row-6-section-parent .col-image-section-6 img {
  width: 100%;
}
.container-6-sect .row-6-section-parent .col-txt-section-6 h2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.01em;
  color: #444444;
  margin-top: 60px;
  margin-bottom: 8px;
}
.container-6-sect .row-6-section-parent .col-txt-section-6 p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: #333333;
  margin-top: 8px;
}
.container-6-sect .row-6-section-parent .col-txt-section-6 .bottom-class6 {
  margin-bottom: 40px;
}

/* *****************footer styling*******************    */
.container-7-footer {
  background-color: #414e5e;
  padding-bottom: 26px;
}
.container-7-footer .row-section-7-parent {
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.container-7-footer .row-section-7-parent .logo-footer-area {
  width: 30% !important;
  margin-top: 24px;
}
.container-7-footer .row-section-7-parent .logo-footer-area img {
  width: 185px;
}
.container-7-footer .row-section-7-parent .logo-footer-area > p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15.5px;
  line-height: 118%;
  text-align: start;
  color: #ffffff;
}
.container-7-footer .row-section-7-parent .social-footer-sec-7 {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.links-footer-section {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.links-footer-section ul {
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 27px 0px;
}
.links-footer-section ul h3 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 33px;
  letter-spacing: 0.055em;
  color: #ffffff;
  margin-bottom: 8px;
}
.portfolio-section-7 {
  width: 40% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start !important;
}
.portfolio-section-7 a {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  margin: 4px 0px;
}
.portfolio-section-7 a:hover {
  color: #f47621;
  cursor: pointer;
}
.find-us-section-7 {
  width: 30% !important;
}
.find-us-section-7 .disply-row2-7 {
  display: flex !important;
  flex-direction: column !important;
}
.footer-row2 {
  margin: 8px 0px;
  width: 100%;
  height: 150px;
}
.footer-row1 {
  margin: 8px 0px;
}
.footer-row1 > p {
  display: flex !important;
}

.find-us-section-7 i {
  color: #fff;
}
.find-us-section-7 p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15.5px;
  line-height: 118%;
  color: #ffffff;
  margin: 6px 0px 5px 6px !important;
  display: inline;
}
.find-us-section-7 i {
  color: #fff;
}
.find-us-section-7 i:hover {
  color: #f47621;
}
.follow-us-section-7 {
  width: 23% !important;
}
.follow-us-section-7 h3 {
  padding-left: 10px;
}
.follow-us-section-7 {
  width: 25% !important;
}
.follow-us-section-7 .row-2-display-flex-7 {
  display: flex;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
}
.follow-us-section-7 a {
  width: 24px;
  height: 24px;
  margin-left: 12px;
  color: #ffffff;
}
.follow-us-section-7 a {
  width: 24px;
  height: 24px;
  color: #ffffff;
  font-size: 25px;
}
.follow-us-section-7 a:hover {
  color: #f47621;
  cursor: pointer;
}

/* ********************** (Chairman and CEO Message) **********   */
.container-chairman-mess1 {
  width: 100%;
  background-color: #e0e0e0;
}
#container-CEO-messag1 {
  background-color: #fff;
}
.row-1-chairman-messag-container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0px;
}
#padding-top-message {
  padding-top: 130px;
}
#ceo-message-parent-flex-con {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.row-1-chairman-messag-container .img-charmain-div {
  width: 35%;
  overflow: hidden;
}
.row-1-chairman-messag-container .img-charmain-div img {
  width: 100%;
  transition: all 0.2s;
}
.row-1-chairman-messag-container .img-charmain-div:hover img {
  transform: scale(1.1);
}
.row-1-chairman-messag-container .txt-chairman-messg-div {
  width: 62%;
  /* border: 1px solid red; */
}
.row-1-chairman-messag-container .txt-chairman-messg-div > h2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.01em;
  color: #444444;
  margin-bottom: 10px;
}
.row-1-chairman-messag-container .txt-chairman-messg-div > p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: #333333;
  margin: 10px 0px;
  padding-right: 6px;
}
.row-1-chairman-messag-container .txt-chairman-messg-div > h3 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #444444;
  margin-top: 10px;
  margin-bottom: 6px;
}
.row-1-chairman-messag-container .txt-chairman-messg-div > h5 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #444444;
}
.container-team12 {
  width: 100%;
  background-color: #f2f2f2;
}
.container-team12 > h2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  color: #414e5e;
  text-align: center;
  padding-bottom: 5px;
  padding-top: 85px;
}
.container-team12 .parent-flex-tean-se {
  width: 80%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.row1-container-team-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.col-team-div1 {
  width: 23%;
  height: 280px;
  margin: 30px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 0.5px solid #000000;
  padding: 7px 0px;
  margin-left: 30px;
}
.col-team-div1 > h4 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  text-transform: capitalize;
  color: #414e5e;
  margin: 6px 0xp;
}
.col-team-div1 > p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #414e5e;
}
.col-team-div1 .image-team-div1 {
  width: 55%;
  /* border: 1px solid red; */
  border-radius: 50%;
  overflow: hidden;
}
.col-team-div1 .image-team-div1 img {
  width: 100%;
}
.row2-container-team-div {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

/* **************** invester Guide css section ****************** */
.container-invstr-guide {
  width: 100%;
  background-color: #f1f1f1;
}
.container-invstr-guide .row-1-parent-flex {
  width: 90%;
  margin: 0px auto;
  padding: 120px 0px 40px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container-invstr-guide .row-1-parent-flex .col-1-txt-section-div {
  width: 60%;
}
.container-invstr-guide .row-1-parent-flex .col-1-txt-section-div h2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #333333;
  margin-bottom: 8px;
}
.container-invstr-guide .row-1-parent-flex .col-1-txt-section-div > p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: #333333;
  margin: 8px 0px;
}
.container-invstr-guide .row-1-parent-flex .col-2-image-section-div {
  width: 37%;
  overflow: hidden;
}
.container-invstr-guide .row-1-parent-flex .col-2-image-section-div img {
  width: 100%;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.container-invstr-guide .row-1-parent-flex .col-2-image-section-div:hover img {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

/* ******************* Approach **********************  */
.container-approach-section {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.container-approach-section .row-1-fix-section {
  width: 100%;
  height: 400px;
  background-image: url("../Images/Approach/Rectangle803-min_11zon.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* flex properties */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.container-approach-section .row-1-fix-section .col-1-heading {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.container-approach-section .row-1-fix-section .col-1-heading > h2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 63px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #333333;
}
.container-approach-section .row-2-items-sec {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.container-approach-section .row-2-items-sec .col-1-box-div-approch {
  width: 40%;
  padding: 0px 10px 10px 10px;
  margin: 25px 0px;
}
.container-approach-section .row-2-items-sec .col-1-box-div-approch:hover {
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  /* filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.3)); */
}
.container-approach-section
  .row-2-items-sec
  .col-1-box-div-approch
  .img-approach-container {
  width: 13%;
}
.container-approach-section
  .row-2-items-sec
  .col-1-box-div-approch
  .img-approach-container
  img {
  width: 100%;
}
.container-approach-section .row-2-items-sec .col-1-box-div-approch > h3 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #333333;
  margin: 8px 0px;
}
.container-approach-section .row-2-items-sec .col-1-box-div-approch > p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: #333333;
}

/* *************************** Stratigic outlook styling start here ***************  */
.container-stragic-outlook {
  width: 100%;
  height: auto;
}
.container-stragic-outlook .row-1-main-image {
  width: 100%;
  margin-bottom: 10px;
  overflow: hidden;
}
.container-stragic-outlook .row-1-main-image img {
  width: 100%;
  height: 522px;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.container-stragic-outlook .row-1-main-image:hover img {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.container-stragic-outlook .row-2-flex-containor-sect {
  width: 90%;
  margin: 0 auto;
}
.container-stragic-outlook .row-2-flex-containor-sect > h2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.01em;
  color: #444444;
  margin: 20px 60px;
}
.col-1-stratigic-contaier-div {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.col-1-stratigic-contaier-div .box-same-css-stratigic {
  width: 32%;
  height: 300px;
  padding: 2.5625rem 4.5625rem 5.5rem 4.375rem;
  background: #e0e0e0;
  box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.18);
  border-radius: 4px;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  transition: 0.2s;
}
.col-1-stratigic-contaier-div .box-same-css-stratigic:hover {
  transform: scale(1.05);
  color: white;
}
.col-1-stratigic-contaier-div .box-same-css-stratigic:nth-child(3) {
  padding: 1.5625rem 2.5625rem 2.5rem 2.375rem;
}
.col-1-stratigic-contaier-div .box-same-css-stratigic:nth-child(3) {
  padding: 1.5625rem 2.5625rem 2.5rem 2.375rem;
}
.col-1-stratigic-contaier-div .box-same-css-stratigic:nth-child(4) {
  padding: 1.5625rem 2.5625rem 2.5rem 2.375rem;
}
.col-1-stratigic-contaier-div .box-same-css-stratigic:nth-child(5) {
  padding: 1.5625rem 2.5625rem 2.5rem 2.375rem;
}
.col-1-stratigic-contaier-div .box-same-css-stratigic:nth-child(6) {
  padding: 1.5625rem 2.5625rem 1.5rem 3.375rem;
}
.col-1-stratigic-contaier-div
  .box-same-css-stratigic
  .image-icon-stratigic-section:nth-child(3) {
  width: 10%;
}
.col-1-stratigic-contaier-div
  .box-same-css-stratigic
  .image-icon-stratigic-section:nth-child(3) {
  width: 10%;
}
.col-1-stratigic-contaier-div
  .box-same-css-stratigic
  .image-icon-stratigic-section:nth-child(4) {
  width: 10%;
}
.col-1-stratigic-contaier-div
  .box-same-css-stratigic
  .image-icon-stratigic-section:nth-child(5) {
  width: 10%;
}
.col-1-stratigic-contaier-div
  .box-same-css-stratigic
  .image-icon-stratigic-section:nth-child(6) {
  width: 10%;
}
.col-1-stratigic-contaier-div
  .box-same-css-stratigic
  .image-icon-stratigic-section {
  width: 15%;
}

.col-1-stratigic-contaier-div
  .box-same-css-stratigic
  .image-icon-stratigic-section
  img {
  width: 100%;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.col-1-stratigic-contaier-div
  .box-same-css-stratigic
  .image-icon-stratigic-section:hover
  img {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}
.col-1-stratigic-contaier-div .box-same-css-stratigic > h2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #333333;
  margin: 16px 0px;
}
.col-1-stratigic-contaier-div .box-same-css-stratigic > p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #333333;
}

.container-outlook-div2 {
  width: 81%;
  margin: 50px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container-outlook-div2 .text-flex-stratigic-sect {
  width: 55%;
}
.container-outlook-div2 .text-flex-stratigic-sect h2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.01em;
  color: #444444;
  margin-bottom: 8px;
}
.container-outlook-div2 .text-flex-stratigic-sect p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: #333333;
  margin: 8px 0px;
}
.container-outlook-div2 .image-flex-stratigic-outlook {
  width: 43%;
}
.container-outlook-div2 .image-flex-stratigic-outlook img {
  width: 100%;
}

/* ******************* Portfolio css start here ****************** */
.container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
#container_2 {
  margin-top: 20px;
}
.container .card {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 2s ease-in;
}
.container .card .icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;

  top: 0;

  left: 0;

  width: 100%;

  height: 100%;

  background: #fff;
}

.container .card .slide {
  width: 300px;
  height: 250px;
  transition: 0.5s;
}

.container .card .slide.slide1 {
  position: relative;

  display: flex;

  justify-content: center;

  align-items: center;

  z-index: 1;

  transition: 0.7s;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transform: translateY(100px);
}

.container .card:hover .slide.slide1 {
  margin-top: 20px;
  transform: translateY(0px);
}

.container .card .slide.slide2 {
  position: relative;

  display: flex;

  justify-content: center;

  align-items: center;

  padding: 20px 10px;

  box-sizing: border-box;

  transition: all 0.8s ease-in;

  transform: translateY(-160px);
  display: none;
}

.container .card:hover .slide.slide2 {
  transform: translateY(0);
  display: block;
  height: auto;
  border: 0.5px solid rgb(128, 125, 125);
}

.container .card .slide.slide2::after {
  content: "";

  position: absolute;

  width: 30px;

  height: 4px;

  bottom: 5px;

  left: 50%;

  transform: translateX(-50%);

  background: #2c73df;
}

.container .card .slide.slide2 .content {
  text-align: center;
  height: auto;
  padding-bottom: 18px;
}

.container .card .slide.slide2 .content h3 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  /* identical to box height, or 36px */

  letter-spacing: 0.05em;

  color: #444444;
  margin: 16px 0px 8px 0px;
}
.container .card .slide.slide2 .content p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 137.52%;
  /* or 23px */

  text-align: center;
  letter-spacing: 0.03em;
  color: #444444;
  margin: 8px 0px 25px 0px;
}
.container .card .slide.slide2 .content a {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #027cc5;
  margin: 8px 0px 16px 0px;
  padding: 12px 24px;
  background: rgba(255, 255, 255, 0.02);
  border: 1.5px solid #027cc5;
  /* Button */
  transition: all 0.8s;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
}
.container .card .slide.slide2 .content a:hover {
  border: 1.5px solid #fff;
  color: #fff;
  background-color: #027cc5;
}

.container-portfolio {
  width: 100%;
  height: auto;
  padding-top: 100px;
  background: #fff;
  padding-bottom: 250px;
}
.container-portfolio h1 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #333333;
  margin: 40px 0px;
  text-align: center;
}
.container-portfolio .row-flex-portfolio-div {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.container-portfolio .row-flex-portfolio-div .col1-Box-div-fortfolio {
  position: relative;
  margin: 70px 0px;
  width: 25% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-text {
  width: 100%;
  position: absolute;
  top: -30% !important;
  left: 0%;
  right: 0%;
  transform: translate(0%, 0px);
  background-color: #ffffff;
  padding: 30px 7px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.7s ease-out;
  text-align: center;
}
.popup-text > h3 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #444444;
}
.popup-text > p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 137.52%;
  text-align: center;
  letter-spacing: 0.03em;
  color: #444444;
  margin-bottom: 20px;
}
.popup-text > a {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #027cc5;
  padding: 15px 7px;
  background: rgba(255, 255, 255, 0.02);
  border: 1.5px solid #027cc5;
  /* Button */
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
}
.container-portfolio
  .row-flex-portfolio-div
  .col1-Box-div-fortfolio:hover
  .popup-text {
  visibility: visible;
  opacity: 1;
}
.container-portfolio
  .row-flex-portfolio-div
  .col1-Box-div-fortfolio:hover
  .front {
  visibility: visible;
  opacity: 0;
}
/* ************** contact us css start here ************* */
.contact_container {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  padding-top: 120px;
  padding-bottom: 90px;
}
.contact_container > h2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  color: #414e5e;
  text-align: center;
  margin-bottom: 10px;
}
.flex_contact_container {
  width: 90%;
  margin: 0 auto;
}
.flex_contact_container > h3 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #333333;
}
.social_contact {
  border-bottom: 1px solid #0993d3;
  padding-bottom: 16px;
}
.social_contact > p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: lowercase;
  color: #444444;
  margin: 8px 0px;
}
.social_contact p > span {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #333333;
  margin: 0px 16px;
}
.media_quries {
  padding-top: 4px;
}
.media_quries > h3 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #333333;
  margin: 16px 0px;
}
.media_quries > p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: lowercase;
  color: #444444;
}
.media_quries > h2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #333333;
  margin: 16px 0px;
}
.social_icons2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.social_icons2 > a {
  color: #444444;
  margin-right: 16px;
  transition: all 0.5s;
}
.social_icons2 > a:hover {
  color: #4e75ca;
  margin-right: 16px;
}
/* ************* Impact_investing *********  */
.Impact_investing {
  width: 100%;
  height: auto;
  padding-top: 100px;
  padding-bottom: 60px;
  background: #f1f1f1;
}
.Impact_investing .container-impact_investing {
  width: 70%;
  margin: 0 auto;
}
.Impact_investing .container-impact_investing > h2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #333333;
  text-align: center;
}
.Impact_investing .container-impact_investing > p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #333333;
  margin: 16px 0px;
}
.Impact_investing .Impact_investing-row1 {
  width: 80%;
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.Impact_investing .Impact_investing-row1 .col-1-impact_investing {
  width: 30%;
  height: 345px;
  border: 1px solid #000000;
  transition: 0.3s ease-in-out;
}
.Impact_investing .Impact_investing-row1 .col-1-impact_investing:hover {
  transform: scale(1.04);
}

.Impact_investing .Impact_investing-row1 .col-1-impact_investing > p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: #000000;
  margin: 0px 20px;
  padding-bottom: 20px;
}
.Impact_investing
  .Impact_investing-row1
  .col-1-impact_investing
  #id_impactSpan {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #333333;
  padding: 18px 20px 16px 20px;
}
/* ***************** (media Quries) *********************  */
@media screen and (max-width: 1024px) {
  .main-container-3
    .row-section-3-parent
    .col-2-contant-sec
    .box-3-section
    .boxs-same-css {
    width: 31%;
    height: auto;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
  }
  .container-portfolio .row-flex-portfolio-div {
    justify-content: space-around;
  }

  .col-team-div1 {
    height: auto;
  }

  .Impact_investing .Impact_investing-row1 .col-1-impact_investing {
    height: auto;
    align-items: stretch;
  }
}
@media screen and (max-width: 855px) {
  .row1 {
    width: 70%;
    margin-top: 170px;
  }
  .main_container .container_parent .row2 .col-img1 {
    width: 68%;
    margin-top: 2px;
  }
  .container_compnies .row_compneis {
    flex-wrap: wrap !important;
    align-items: center;
    justify-content: flex-start;
  }
  .container_compnies .row_compneis .col1_compnes {
    /* border: 2px solid red; */
    height: auto;
    width: 20%;
  }
  /* invester companies part  */
  .main-container-3 .row-section-3-parent {
    flex-direction: column;
  }
  .main-container-3 .row-section-3-parent .col-1-image1-sec {
    width: 100%;
  }
  .main-container-3 .row-section-3-parent .col-2-contant-sec {
    width: 100%;
    margin: 24px 0px;
  }
  .container-5-section .row1-section-5-parent {
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  .container-5-section .row1-section-5-parent .col-box1-section-5 {
    width: 40%;
    padding-bottom: 30px;
  }

  .container-6-sect .row-6-section-parent {
    flex-direction: column;
    margin-left: 0px;
    width: 90%;
    margin: 0 auto;
  }
  .container-6-sect .row-6-section-parent .col-image-section-6 {
    width: 100%;
  }
  .container-6-sect .row-6-section-parent .col-txt-section-6 {
    width: 100%;
  }

  /* ************* team ***************   */
  .container-team12 .parent-flex-tean-se {
    width: 90%;
  }

  /* ***************** invester guide ***************** */
  .container-invstr-guide .row-1-parent-flex {
    flex-direction: column;
  }
  .container-invstr-guide .row-1-parent-flex .col-1-txt-section-div {
    width: 100%;
  }
  .container-invstr-guide .row-1-parent-flex .col-2-image-section-div {
    width: 100%;
    margin-top: 15px;
  }

  /* ********** Approach ********************** */
  .container-approach-section .row-2-items-sec {
    width: 90%;
  }
  .container-approach-section .row-2-items-sec .col-1-box-div-approch {
    width: 48%;
    margin: 20px 0px;
  }
  .container-approach-section
    .row-2-items-sec
    .col-1-box-div-approch
    .img-approach-container {
    width: 16%;
  }

  /* ******************* stratigic outlooks ******************* */
  .container-stragic-outlook .row-1-main-image img {
    height: auto;
  }
  .container-stragic-outlook .row-2-flex-containor-sect > h2 {
    margin: 20px 0px;
    text-align: center;
  }
  .col-1-stratigic-contaier-div .box-same-css-stratigic {
    width: 48%;
    height: 300px;
    padding: 1.5625rem 1.5625rem 2.5rem 1.375rem;
    padding: 2.5625rem 4.5625rem 5.5rem 4.375rem;
    background: #e0e0e0;
    box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
  }
  .container-outlook-div2 {
    flex-direction: column;
  }
  .container-outlook-div2 .text-flex-stratigic-sect {
    width: 100%;
  }
  .container-outlook-div2 .image-flex-stratigic-outlook {
    width: 100%;
  }
  /* *************** portfolio *************  */
  .container-portfolio h1 {
    font-size: 30px;
    line-height: 30px;
    margin: 20px 0px;
  }
  .container-portfolio .row-flex-portfolio-div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .container-portfolio .row-flex-portfolio-div .col1-Box-div-fortfolio {
    width: 80% !important;
    margin: 64px 0px;
  }
  /* ************footer *********   */
  .container-7-footer .row-section-7-parent {
    width: 97%;
  }
  .container-7-footer .row-section-7-parent .social-footer-sec-7 {
    width: 80%;
  }

  .row-1-chairman-messag-container {
    width: 90%;
    margin: 0 auto;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .row-1-chairman-messag-container .txt-chairman-messg-div {
    width: 100%;
  }
  #ceo-message-parent-flex-con {
    flex-direction: column-reverse;
  }

  /* ************ .Impact_investing *******  */
  .Impact_investing .Impact_investing-row1 .col-1-impact_investing {
    width: 48%;
    height: 400px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  .lottie_div {
    display: none;
  }

  .container_compnies .row_compneis .col1_compnes {
    height: auto;
    width: 30%;
  }

  .container-7-footer .row-section-7-parent {
    flex-direction: column;
    width: 100%;
  }
  .container-7-footer .row-section-7-parent .logo-footer-area {
    width: 83% !important;
    margin: 10px auto !important;
  }
  .links-footer-section {
    flex-direction: column;
  }
  .links-footer-section ul {
    width: 100% !important;
    margin: 5px 0px;
  }
  .col-1-stratigic-contaier-div .box-same-css-stratigic {
    width: 48%;
    height: 300px;
    padding: 1.5625rem 1.5625rem 2.5rem 1.375rem;
  }

  .container-invester-guide .row1-parent-invter-guide .txt-invster-g {
    width: 85%;
  }
  .container-invester-guide .row1-parent-invter-guide .txt-invster-g h2 {
    font-size: 25px;
    line-height: 30px;
  }
  .container-invester-guide .row1-parent-invter-guide .txt-invster-g p {
    font-size: 15px;
    line-height: 18px;
    margin-top: 8px;
  }
  .container-invester-guide .row1-parent-invter-guide .txt-invster-g a {
    font-size: 15px;
    line-height: 18px;
    padding: 9px 18px;
  }

  .row2-container-team-div {
    align-items: baseline;
  }
  /* *****contact us **** */
  .container-contactUs .row-flex-contact_us {
    width: 80%;
  }

  /* ******* .Impact_investing ** */
  .image_impact_investing {
    background-position: center center;
  }
  .cards_impact_container {
    flex-direction: column;
  }
  .cards_impact_container .impact_cards {
    padding: 10px 20px;
    width: 80%;
    height: auto;
    margin: 8px 0px;
  }
  .Impact_investing .Impact_investing-row1 {
    justify-content: center;
  }
  .Impact_investing .Impact_investing-row1 .col-1-impact_investing {
    width: 60%;
    height: auto;
    margin-bottom: 20px;
  }
  .container {
    justify-content: center;
  }
  .container .card .slide.slide1 {
    margin-top: 30px;
  }
}
@media screen and (max-width: 540px) {
  .row1 {
    width: 100%;
    margin-top: 170px;
  }
  .main_container .container_parent .row2 .col-img1 {
    width: 100%;
    margin-top: 2px;
  }
  .row1 h3 {
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 5px;
  }
  .main_container .container_parent .row2 {
    width: 100%;
  }
  .social_icon {
    width: 100%;
    flex-direction: column !important;
  }
  .social_icons_container {
    justify-content: center;
  }
  .row1 h2 {
    font-size: 20px;
    line-height: 43px;
  }
  .container_compnies h2 {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 30px;
  }
  .container_compnies .row_compneis {
    justify-content: center;
  }
  .container_compnies .row_compneis .col1_compnes {
    height: auto;
    width: 40%;
  }
  .main-container-3 .row-section-3-parent .col-2-contant-sec .box-3-section {
    flex-direction: column;
    height: auto;
  }
  .main-container-3
    .row-section-3-parent
    .col-2-contant-sec
    .box-3-section
    .boxs-same-css {
    width: 100% !important;
  }

  .main-container-3 .row-section-3-parent .col-2-contant-sec h2 {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 10px;
  }
  .main-container-3 .row-section-3-parent .col-2-contant-sec p {
    font-size: 15px;
    line-height: 21px;
  }
  .container-5-section .row1-section-5-parent .col-box1-section-5 .h3-heading {
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 4px;
  }
  .container-5-section .row1-section-5-parent .col-box1-section-5 p {
    font-size: 15px;
  }
  .container-6-sect .row-6-section-parent .col-txt-section-6 h2 {
    font-size: 25px;
    line-height: 35px;
    margin-top: 30px;
    margin-bottom: 5px;
  }
  .container-6-sect .row-6-section-parent .col-txt-section-6 p {
    font-size: 15px;
    line-height: 20px;
    margin-top: 6px;
  }

  /* ************* team *******************  */
  .row1-container-team-div {
    flex-direction: column;
  }
  .col-team-div1 {
    width: 70%;
    margin: 9px 0px;
  }

  /* ********* invester guide ************** */
  .container-invstr-guide .row-1-parent-flex .col-1-txt-section-div h2 {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 5px;
  }
  .container-invstr-guide .row-1-parent-flex .col-1-txt-section-div > p {
    font-size: 15px;
    line-height: 20px;
    margin: 5px 0px;
  }
  /* ************* Approach *****************  */
  .container-approach-section .row-2-items-sec {
    flex-direction: column;
  }
  .container-approach-section .row-2-items-sec .col-1-box-div-approch {
    width: 100%;
  }
  .container-approach-section .row-1-fix-section .col-1-heading > h2 {
    font-size: 35px;
    line-height: 40px;
  }
  .container-approach-section .row-2-items-sec .col-1-box-div-approch > h3 {
    font-size: 20px;
    line-height: 24px;
    margin: 5px 0px;
  }
  .container-approach-section .row-2-items-sec .col-1-box-div-approch > p {
    font-size: 15px;
    line-height: 19px;
  }

  /* ****************  stratigic outlook ***************** */
  .container-stragic-outlook .row-2-flex-containor-sect > h2 {
    font-size: 30px;
    line-height: 20px;
  }
  .col-1-stratigic-contaier-div .box-same-css-stratigic {
    width: 100%;
    height: 300px;
    padding: 1.5625rem 2.5625rem 2.5rem 2.375rem;
  }
  .col-1-stratigic-contaier-div .box-same-css-stratigic > h2 {
    font-size: 20px;
    line-height: 20px;
    margin: 12px 0px;
  }
  .col-1-stratigic-contaier-div .box-same-css-stratigic > p {
    font-size: 15px;
    line-height: 20px;
  }
  .container-outlook-div2 {
    margin: 15px auto;
  }
  .container-outlook-div2 .text-flex-stratigic-sect h2 {
    font-size: 26px;
    line-height: 30px;
  }
  .container-outlook-div2 .text-flex-stratigic-sect p {
    font-size: 15px;
    line-height: 20px;
    margin: 5px 0px;
  }
  /* ************* portfolio ************** * */
  .container-portfolio .row-flex-portfolio-div .col1-Box-div-fortfolio {
    width: 75%;
  }
  .container-portfolio .row-flex-portfolio-div .col1-Box-div-fortfolio > h3 {
    font-size: 20px;
    line-height: 80%;
    margin: 14px 0px;
  }
  .container-portfolio .row-flex-portfolio-div .col1-Box-div-fortfolio > p {
    font-size: 15px;
    line-height: 130.52%;
    margin-bottom: 12px;
  }
  .container-portfolio .row-flex-portfolio-div .col1-Box-div-fortfolio > a {
    font-size: 15px;
    line-height: 15px;
    padding: 10px 20px;
  }
  /* *************** footer ******    */
  .container-7-footer .row-section-7-parent {
    width: 88%;
  }
  .social-footer-sec-7 .submit-footer-form input {
    padding: 10px 6px;
    font-size: 13px;
  }
  .social-footer-sec-7 .submit-footer-form a {
    font-size: 13px;
    line-height: 15px;
    padding: 10px 16px;
    margin-left: -81px;
  }
  .links-footer-section ul h3 {
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 5px;
  }
  .follow-us-section-7 h3 {
    padding-left: 0px;
  }
  .portfolio-section-7 a {
    font-size: 13px;
    line-height: 15px;
    margin: 2px 0px;
  }
  .find-us-section-7 p {
    font-size: 12.5px;
    line-height: 133%;
  }
  .follow-us-section-7 a {
    margin-left: 6px;
  }
  .follow-us-section-7 a i {
    font-size: 20px;
  }

  .row-1-chairman-messag-container .img-charmain-div {
    width: 75%;
    margin-right: 0px;
    margin-top: 10px;
  }
  .row-1-chairman-messag-container .txt-chairman-messg-div > h2 {
    font-size: 34px;
    line-height: 35px;
  }
  .row-1-chairman-messag-container .txt-chairman-messg-div > p {
    font-size: 15px;
    line-height: 20px;
    margin: 10px 0px;
    padding-right: 0px;
  }
  .row-1-chairman-messag-container .txt-chairman-messg-div > h3 {
    font-size: 20px;
    line-height: 24px;
    margin-top: 5px;
    margin-bottom: 4px;
  }
  .row-1-chairman-messag-container .txt-chairman-messg-div > h5 {
    font-size: 13px;
    line-height: 15px;
  }
  /* *****contact us **** */
  .contact_container > h2 {
    font-size: 24px;
    line-height: 32px;
  }
  .flex_contact_container > h3 {
    font-size: 16px !important;
    line-height: 24px;
    letter-spacing: 0em;
  }
  .social_contact > p {
    font-size: 14px;
    margin: 6px 0px;
  }
  .social_contact p > span {
    font-size: 16px;
    margin: 0px 7px;
  }
  .media_quries > h3 {
    font-size: 16px;
    margin: 10px 0px;
  }
  .media_quries > p {
    font-size: 16px !important;
  }
  .media_quries > h2 {
    font-size: 18px;
    line-height: 20px;
    margin: 10px 0px;
  }
  /* ********** .Impact_investing ****/
  .cards_impact_container .impact_cards > h2 {
    font-size: 16px;
  }
  .cards_impact_container .impact_cards > p {
    font-size: 14px;
  }
  .cards_impact_container .impact_cards > a {
    font-size: 14px;
  }

  .Impact_investing {
    padding-bottom: 30px;
  }
  .Impact_investing .container-impact_investing > h2 {
    font-size: 25px;
    line-height: 30px;
  }
  .Impact_investing .container-impact_investing > p {
    font-size: 14px;
    line-height: 20px;
    margin: 10px 0px;
  }
  .col-1-impact_investing {
    width: 90% !important;
  }
}

@media screen and (max-width: 375px) {
  .container_compnies .row_compneis .col1_compnes {
    height: auto;
    width: 100%;
    background-color: #fff;
    margin-bottom: 20px;
  }
  .main_container .container_parent .row2 .col1 {
    margin-right: 4px;
  }

  .container-5-section .row1-section-5-parent .col-box1-section-5 {
    width: 95%;
    padding-bottom: 30px;
    margin: 15px 0px !important;
    background-color: #ffffff;
  }
  .row-1-chairman-messag-container .img-charmain-div {
    width: 100%;
  }
  .row-1-chairman-messag-container .txt-chairman-messg-div > h2 {
    font-size: 26px;
  }
  .row-1-chairman-messag-container .txt-chairman-messg-div > p {
    font-size: 14px;
    line-height: 19px;
    margin: 0px 0px;
    padding-right: 0px;
  }

  .container-portfolio h1 {
    font-size: 25px;
  }
  .container-portfolio .row-flex-portfolio-div .col1-Box-div-fortfolio {
    width: 100% !important;
    margin: 80px 0px;
  }
  .image_impact_investing .impact_center_flex {
    padding: 12px 0px;
  }
  .cards_impact_container {
    margin: 5px 0px;
  }
  .cards_impact_container .impact_cards {
    padding: 10px 7px;
    width: 100%;
  }
  .impact_paragraph > p {
    font-size: 14px;
  }
  .impact_paragraph > a {
    font-size: 14px;
    padding: 9px 20px;
  }

  .col-1-impact_investing {
    width: 100%;
  }
  .Impact_investing
    .Impact_investing-row1
    .col-1-impact_investing
    #id_impactSpan {
    font-size: 17px;
    line-height: 18px;
    padding: 18px 20px 10px 20px !important;
  }
  .Impact_investing .Impact_investing-row1 .col-1-impact_investing > p {
    font-size: 14px;
    line-height: 18px;
  }

  .container-7-footer .row-section-7-parent .logo-footer-area {
    margin-top: 20px;
  }
}
