

.container-team {
  padding: 100px 0px;
height: 100%;
}
.main {
  padding: 0px 60px;
  height: auto;
}
.slick-slide>div{
  margin: 0px 20px;
}
.slick-slide>div:nth-child(1){
 margin-left: 0px;
}
.slick-list {
  margin-right: -20px;
}

@media screen and (width:768px) {
  .team_container {
    height:auto !important;
  }
}
@media screen and(width:850px) {
  #custom_teamLine {
    top: 480px !important;
  }
}
